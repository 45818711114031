define('tm3/services/report-catalog', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    /* /////////////////////////////////////////////////////////////////////////////////////////////////////////
        COMPLETE LIST OF OPTIONS FOR REPORT OBJECT                                                            //
        - apiName      - The name the report on the api                                                       //
        - displayName  - The name that will be shown on the table header                                      //
        - perPage      - The pagination limit default                                                         //
        - route        - The Ember route that the report is located at                                        //
        - description  - A description of the report                                                          //
        - component    - An optional custom component for the table layout for the report                     //
        - subReport    - A boolean for whether report stands on its own (will not be on list page if true)    //
        - parentReport - Optional - designates parent report of a sub report                                  //
       ///////////////////////////////////////////////////////////////////////////////////////////////////////// */

    // report object
    var Report = Ember.Object.extend({
        apiName: 'CHANGE ME',
        perPage: 100,
        component: 'report/default-table',
        displayName: 'CHANGE ME',
        description: null,
        category: 'General',
        route: 'report.list'
    });

    /**
     * a service to store all client side knowledge of our api reports
     */
    exports.default = Ember.Service.extend({
        catalog: [],

        /**
         * populate the catalog with all known reports
         */
        onInit: Ember.on('init', function () {
            this.catalog.push(Report.create({
                apiName: 'fake_report',
                displayName: 'Fake Report',
                perPage: 500,
                route: 'report.info.fake-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'client_file_uploads',
                displayName: 'Client File Report',
                description: 'List recently uploaded files by Client Portal users',
                component: 'report/client-upload-table',
                route: 'report.info.client-file-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'utilization_report',
                displayName: 'Utilization Report',
                perPage: 500,
                description: 'Show Billing Information',
                route: 'report.info.utilization-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'review_time_report',
                displayName: 'Review Time Report',
                perPage: 500,
                description: 'Show time related to a person or case',
                route: 'report.info.review-time-report',
                subReport: true,
                parentReport: 'utilization_report'
            }));
            this.catalog.push(Report.create({
                apiName: 'sales_report',
                displayName: 'Sales Report',
                perPage: 500,
                description: 'Gathers together groups of cases and calculates summary style invoice data for each group',
                component: 'report/sales-report',
                route: 'report.info.sales-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'billed_cases_by_month',
                displayName: 'Monthly Billed Invoices By Case Type',
                perPage: 500,
                description: 'Billed Invoices for a given month grouped by Case Type. Drill down to see further details for each case.',
                component: 'report/billed-cases-by-month',
                route: 'report.info.billed-cases-by-month'
            }));
            this.catalog.push(Report.create({
                apiName: 'billed_cases_detail',
                displayName: 'Billed Cases Detail',
                perPage: 500,
                description: 'Allows a user to see the billed items for a given month and case type',
                component: 'report/billed-cases-detail',
                route: 'report.info.billed-cases-detail',
                subReport: true
            }));
            this.catalog.push(Report.create({
                apiName: 'new_cases_report',
                displayName: 'Case Type Summary',
                perPage: 500,
                description: 'Group cases by when they were created. Drill down to see a list of cases along with basic summary of billed data',
                component: 'report/new-cases-table',
                route: 'report.info.new-cases'
            }));
            this.catalog.push(Report.create({
                apiName: 'new_cases_detail_report',
                displayName: 'Case Type Details',
                perPage: 500,
                description: 'Show a list of the cases that were created in a particular month',
                component: 'report/new-cases-detail',
                route: 'report.info.new-cases-detail',
                subReport: true
            }));
            this.catalog.push(Report.create({
                apiName: 'revenue_summary',
                displayName: 'Revenue Summary',
                perPage: 500,
                description: 'Display a list of Cases or Employees along with their billed Hours, Time and Cost',
                component: 'report/revenue-summary',
                route: 'report.info.revenue-summary'
            }));
            this.catalog.push(Report.create({
                apiName: 'billing_report_by_person',
                displayName: 'Billing Report By Person',
                perPage: 500,
                description: 'Display billing information for employee by case',
                component: 'report/billing-report-by-person',
                route: 'report.info.billing-report-by-person'
            }));
            this.catalog.push(Report.create({
                apiName: 'managers_billing_report',
                displayName: 'Managers Billing Report',
                perPage: 500,
                description: 'A table showing budgets for invoice groups',
                component: 'report/managers-billing-report',
                route: 'report.info.managers-billing-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'time_reports',
                displayName: 'Case Billing Reports',
                perPage: 500,
                description: '',
                route: ''
            }));
            this.catalog.push(Report.create({
                apiName: 'employee_time_reports',
                displayName: 'Case Billing Reports by employee',
                perPage: 500,
                description: '',
                route: '',
                subReport: true,
                parentReport: 'time_reports'
            }));
            this.catalog.push(Report.create({
                apiName: 'billable_type_time_reports',
                displayName: 'Case Billing Reports by billable type',
                perPage: 500,
                description: '',
                route: '',
                subReport: true,
                parentReport: 'time_reports'
            }));
            this.catalog.push(Report.create({
                apiName: 'case_profitability_report',
                displayName: 'Case Profitability Report',
                perPage: 500,
                description: 'The report is intended to give case managers a high level view of a cases profitability',
                component: 'report/case-profitability-report',
                route: 'report.info.case-profitability-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'revenue_quickbooks_report',
                displayName: 'Revenue Report',
                perPage: 500,
                description: 'A report to export invoice data in a format compatible with QuickBooks',
                route: 'report.info.revenue-quickbooks-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'expense_receipt',
                displayName: 'Expense Receipts',
                perPage: 500,
                description: 'A report showing expenses in pending invoices and whether they have receipts or not.',
                component: 'report/expense-receipt',
                route: 'report.info.expense-receipt'
            }));
            this.catalog.push(Report.create({
                apiName: 'prelim_by_case_type',
                displayName: 'Preliminary Invoices by Case Type',
                perPage: 500,
                description: 'A list of preliminary invoices (not yet billed/unbilled) grouped by case type.',
                component: 'report/prelim-by-case-type',
                route: 'report.info.prelim-by-case-type'
            }));
            this.catalog.push(Report.create({
                apiName: 'wip_by_case',
                displayName: 'WIP by Case',
                perPage: 500,
                description: 'List all Service and Expense revenue by case. This report only shows WIP and excludes billed data.',
                component: 'report/wip-by-case',
                route: 'report.info.wip-by-case'
            }));
            this.catalog.push(Report.create({
                apiName: 'cut_time_entries',
                displayName: 'Cut Time Entries',
                perPage: 500,
                description: 'Show time entries where time was cute grouped by either case or employee',
                component: 'report/cut-time-entries',
                route: 'report.info.cut-time-entries'
            }));
            this.catalog.push(Report.create({
                apiName: 'credit_card_reconciliation',
                displayName: 'Credit Card Reconciliation',
                perPage: 500,
                description: 'Display all Credit Card expenses for a specified date range grouped by employee.',
                component: 'report/credit-card-reconciliation',
                route: 'report.info.credit-card-reconciliation'
            }));
            this.catalog.push(Report.create({
                apiName: 'my_time',
                displayName: 'My Time',
                perPage: 500,
                description: 'Show timekeepers how much time they billed over a date range.',
                component: 'report/my-time',
                route: 'report.info.my-time'
            }));
            this.catalog.push(Report.create({
                apiName: 'travel_receipt',
                displayName: 'Travel Receipts',
                perPage: 500,
                description: 'A report showing travel expenses and whether they have receipts or not.',
                component: 'report/travel-receipt',
                route: 'report.info.travel-receipt'
            }));
            this.catalog.push(Report.create({
                apiName: 'unpaid_invoice_outputs',
                displayName: 'Unpaid Invoice Outputs',
                perPage: 500,
                description: 'A report showing Invoice Outputs that have yet to be paid.',
                component: 'report/unpaid-invoice-outputs',
                route: 'report.info.unpaid-invoice-outputs'
            }));
            this.catalog.push(Report.create({
                apiName: 'combined_reimbursement_report',
                displayName: 'Combined Reimbursement Report',
                perPage: 500,
                description: 'A combined view of reimbursed expenses from both Time and Travel records.',
                component: 'report/combined-reimbursement',
                route: 'report.info.combined-reimbursement'
            }));
            this.catalog.push(Report.create({
                apiName: 'timekeeper_rate_sheet',
                displayName: 'Timekeeper Rate Sheet',
                perPage: 2000,
                description: 'A report that shows to case manager/case owners the rates of the the employees related to a case'
            }));
            this.catalog.push(Report.create({
                apiName: 'payment_report',
                displayName: 'Payment Report',
                perPage: 2000,
                description: 'This report will show Accounting records that were successfully uploaded from the QuickBooks report',
                component: 'report/payment-report',
                route: 'report.info.payment-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'budget_utilization',
                displayName: 'Budget Utilization',
                perPage: 2000,
                description: 'This report will show a list of budgets system wide that have crossed the threshold of 80% of utilization',
                component: 'report/budget-utilization-report',
                route: 'report.info.budget-utilization'
            }));
            this.catalog.push(Report.create({
                apiName: 'bonus_report',
                displayName: 'Bonus Report',
                perPage: 500,
                description: 'A report to show bonus per employee in a specific month',
                route: 'report.info.bonus-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'fixed_cost_database_report',
                displayName: 'Fixed Cost Database Report',
                perPage: 500,
                description: 'A report to show fixed cost database usage',
                route: 'report.info.fixed-cost-database-report'
            }));
            this.catalog.push(Report.create({
                apiName: 'report_sent_activity',
                displayName: 'Global Report Activity',
                perPage: 100,
                description: 'Show a list of reports sent from the system to the client from all cases',
                route: 'report.info.report-sent-activity'
            }));
            this.catalog.push(Report.create({
                apiName: 'project_tracker_report',
                displayName: 'Project Tracker',
                perPage: 100,
                description: 'List of cases with global view fields',
                route: 'report.info.project-tracker',
                component: 'report/project-tracker-table'
            }));
            this.catalog.push(Report.create({
                apiName: 'invoiced_customers',
                displayName: 'Invoiced - Customers',
                perPage: 100,
                description: 'List of customers with invoices',
                route: 'report.info.invoiced-customers'
            }));
            this.catalog.push(Report.create({
                apiName: 'invoiced_invoices',
                displayName: 'Invoiced - Invoices',
                perPage: 100,
                description: 'List of invoices in a range of dates',
                route: 'report.info.invoiced-invoices'
            }));
            this.catalog.push(Report.create({
                apiName: 'invoiced_payments',
                displayName: 'Invoiced - Payments',
                perPage: 100,
                description: 'List of payments in a range of dates',
                route: 'report.info.invoiced-payments'
            }));
            this.catalog.push(Report.create({
                apiName: 'caseload_planner',
                displayName: 'Caseload Planner',
                perPage: 100,
                description: 'List of future caseload',
                route: 'report.info.caseload-planner'
            }));
        }),

        /**
         * return a particular report based on it's apiName
         *
         * @param apiName
         * @returns {*|Object}
         */
        getReport: function getReport(apiName) {
            return this.get('catalog').findBy('apiName', apiName);
        },


        /**
         * return a list of reports, but just a sub set of the data
         * suitable for display
         * todo expand to group reports by category
         * @returns {Array}
         */
        getReportList: function getReportList() {
            return this.catalog;
        }
    });
});