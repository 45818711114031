define('tm3/pods/me/time/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route', 'ember-cli-table-pagination/mixins/table-data/column'], function (exports, _route, _column) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),
        permissions: Ember.inject.service(),
        currentUser: Ember.inject.service(),

        modelName: 'billable-group',
        currentRoute: 'me.time.list',

        queryParams: {
            matter_id: {
                refreshModel: true
            },
            start: {
                refreshModel: true
            },
            end: {
                refreshModel: true
            },
            status: {
                refreshModel: true
            },
            currentView: {
                refreshModel: false
            }
        },

        model: function model(params) {
            var myParams = params;

            myParams.created_by_id = this.get('session.data.authenticated.id');
            myParams.bg_type = 'Time';
            var status = myParams.status;


            if (status !== 'All') {
                myParams.approval_status = status;
            }

            if (status === 'Not Invoiced') {
                myParams.approval_status = 'null';
            }

            var initialStart = myParams.start;

            if (myParams.start && myParams.end) {
                myParams.start = '~' + myParams.start + '~' + myParams.end;
            } else {
                if (myParams.start) {
                    myParams.start = '>=' + myParams.start;
                }

                if (myParams.end) {
                    myParams.start = '<=' + myParams.end;
                }
            }

            if (myParams.matter_id && myParams.matter_id === 'null') {
                delete myParams.matter_id;
            }

            delete myParams.refresh;
            delete myParams.status;
            delete myParams.end;
            delete myParams.currentView;

            return Ember.RSVP.hash({
                billableGroupsParams: params,
                selectedMatter: params.matter_id ? this.store.findRecord('matter', params.matter_id) : null,
                initialStart: initialStart,
                params: myParams
            });
        },
        setupController: function setupController(controller, resolved) {
            controller.setProperties(resolved);
            controller.set('loadDataParams', resolved.billableGroupsParams);
            this._super(controller, resolved.billableGroups);
            var columns = this.getColumnsList();

            controller.set('columns', columns);
            controller.set('totals', {
                hours: '...',
                service: '...',
                expenses: '...',
                total: '...',
                count_expenses: '...',
                count_receipts: '...'
            });
            this.get('ajax').request('billable_groups/my-time-totals', {
                data: resolved.params
            }).then(function (totals) {
                controller.set('totals', totals.totals[0]);
            });
            controller.get('populateCaseOptions').perform(resolved.initialStart);
        },
        getColumnsList: function getColumnsList() {
            var i = 1;

            var columns = [_column.default.create({
                displayName: 'Date',
                fieldName: 'start',
                cellComponent: 'custom-table-pagination.date-cell',
                width: '80px',
                order: i
            }), _column.default.create({
                displayName: 'File #',
                fieldName: 'tmCode',
                disableServerInteractions: false,
                cellComponent: 'me.matter-cell',
                width: '70px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Case Nickname',
                fieldName: 'mainBillables.firstObject.matter.caseNickname',
                disableServerInteractions: true,
                width: '250px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Budget',
                fieldName: 'mainBillables.firstObject.budget.name',
                disableServerInteractions: true,
                width: '160px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Type',
                fieldName: 'mainBillables.firstObject.billableType.name',
                disableServerInteractions: true,
                width: '130px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Billable Hours',
                fieldName: 'formattedBillableHours',
                cellComponent: 'accounting/billing/billable-hours-cell',
                disableServerInteractions: true,
                order: i += 1,
                width: '55px'
            }), _column.default.create({
                displayName: 'Non-Billable / Cut Hours',
                fieldName: 'formattedNonBillableHours',
                cellComponent: 'accounting/billing/non-billable-hours-cell',
                disableServerInteractions: true,
                order: i += 1,
                width: '55px'
            }), _column.default.create({
                displayName: 'Total Service',
                fieldName: 'formattedTotalService',
                disableServerInteractions: true,
                width: '92px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Total Expense',
                fieldName: 'formattedTotalExpense',
                disableServerInteractions: true,
                width: '92px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Grand Total',
                fieldName: 'formattedTotal',
                disableServerInteractions: true,
                width: '87px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Expenses (Receipts)',
                fieldName: 'totalExpensesAndReceipts',
                cellComponent: 'me.expense-receipt-cell',
                disableServerInteractions: true,
                width: '78px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Status',
                fieldName: 'approvalStatus',
                cellComponent: 'me.status-cell',
                width: '100px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Cut Reason',
                fieldName: 'mainBillables.firstObject.cutDescription',
                width: '300px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Cut Options',
                fieldName: 'mainBillables.firstObject.cutReasons',
                width: '150px',
                order: i += 1
            }), _column.default.create({
                displayName: 'Trainee',
                fieldName: 'mainBillables.firstObject.trainee.fullName',
                width: '200px',
                order: i += 1
            })];

            return columns;
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            },
            addBillable: function addBillable(matterId, billType) {
                this.transitionTo('me.time.edit', 'new', {
                    queryParams: {
                        canSelectMatter: true,
                        billable_type: billType,
                        matterId: matterId
                    }
                });
            },
            addBillableFromCard: function addBillableFromCard(matterId) {
                this.transitionTo('me.time.edit', 'new', {
                    queryParams: {
                        canSelectMatter: true,
                        billable_type: 'Time',
                        matterId: matterId
                    }
                });
            }
        }
    });
});