define('tm3/pods/matter/admin/config/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        currentMatter: Ember.inject.service(),

        model: function model() {
            var matterId = this.get('currentMatter.id');

            return Ember.RSVP.hash({
                jsSubjectSort: this.store.query('appl-config', {
                    name: ['matters.default.js_subject_sort', 'matters.' + matterId + '.js_subject_sort']
                }),
                scPortalDashboardType: this.store.query('appl-config', {
                    name: ['matters.default.sc_portal_dashboard_type', 'matters.' + matterId + '.sc_portal_dashboard_type']
                }),
                scPortalReportTab: this.store.query('appl-config', {
                    name: ['matters.default.sc_portal_report_tab', 'matters.' + matterId + '.sc_portal_report_tab']
                }),
                matter: this.get('currentMatter.matter'),
                hasSubjectDueDate: this.get('currentMatter.matter').getMatterConfiguration({
                    config: 'has_subject_due_date'
                }).then(function (response) {
                    return response.value;
                }),
                includeRelationshipInDocName: this.get('currentMatter.matter').getMatterConfiguration({
                    config: 'include_relationship_in_document_name'
                }).then(function (response) {
                    return response.value;
                }),
                showFieldOnlyIfValueYes: this.get('currentMatter.matter').getMatterConfiguration({
                    config: 'show_field_only_if_value_yes'
                }).then(function (response) {
                    return response.value;
                }),
                allowEditLocation: this.get('currentMatter.matter').getMatterConfiguration({
                    config: 'allow_edit_location'
                }).then(function (response) {
                    return response.value;
                }),
                allowEditNotes: this.get('currentMatter.matter').getMatterConfiguration({
                    config: 'allow_edit_notes'
                }).then(function (response) {
                    return response.value;
                }),
                allowEditRanking: this.get('currentMatter.matter').getMatterConfiguration({
                    config: 'allow_edit_ranking'
                }).then(function (response) {
                    return response.value;
                }),
                showClientTagsInCards: this.get('currentMatter.matter').getMatterConfiguration({
                    config: 'show_client_tags_in_cards'
                }).then(function (response) {
                    return response.value;
                })
            });
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});