define('tm3/pods/accounting/billing/travel-expenses/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route', 'moment'], function (exports, _route, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        currentUser: Ember.inject.service(),
        modelName: 'billable_group',
        currentRoute: 'accounting.billing.travel-expenses',

        queryParams: {
            filter: {
                refreshModel: true
            },
            status: {
                refreshModel: true
            },
            employee: {
                refreshModel: true
            },
            from: {
                refreshModel: true
            },
            to: {
                refreshModel: true
            },
            byDate: {
                refreshModel: true
            }
        },

        requireAnyPermission: ['Manage Time & Expense', 'See Time & Expense', 'Manage All Travel Expenses'],

        model: function model(params) {
            params.bg_type = 'Travel';
            switch (params.filter) {
                case 'Needs Reimbursement':
                    params['reimbursed_on'] = 'NULL';
                    break;
                case 'Reimbursed':
                    params['reimbursed_on'] = '!=NULL';
                    break;
            }

            var status = params['status'];
            if (status !== 'All') {
                params['approval_status'] = status;
            }

            if (params.employee) {
                params['created_by_id'] = params.employee;
            }

            var from = void 0,
                to = void 0,
                dateToFilterValue = void 0;
            var dateToFilter = params.byDate === 'Expense Date' ? 'start' : 'reimbursed_on';

            if (Ember.isPresent(params.from) && Ember.isPresent(params.to)) {
                from = params.from.substring(0, 10);
                to = params.to.substring(0, 10);
                dateToFilterValue = '~' + from + '~' + to;
            }

            if (Ember.isPresent(params.from) && !Ember.isPresent(params.to)) {
                from = params.from.substring(0, 10);
                to = to = (0, _moment.default)().format('YYYY-MM-DD');
                dateToFilterValue = '~' + from + '~' + to;
            }

            if (!Ember.isPresent(params.from) && Ember.isPresent(params.to)) {
                to = params.to.substring(0, 10);
                dateToFilterValue = '<=' + to;
            }

            if (dateToFilterValue) {
                params[dateToFilter] = dateToFilterValue;
            }

            delete params.status;
            delete params.from;
            delete params.to;
            delete params.byDate;
            delete params.employee;
            delete params.filter;

            return params;
        },
        resetController: function resetController(controller, isExiting, transition) {
            if (isExiting) {
                var queryParams = controller.get('queryParams');
                queryParams.forEach(function (param) {
                    switch (param) {
                        case 'status':
                            controller.set(param, 'New');
                            break;
                        case 'filter':
                            controller.set(param, 'All');
                            break;
                        case 'byDate':
                            controller.set(param, 'Expense Date');
                            break;
                        default:
                            controller.set(param, null);
                            break;
                    }
                });
                controller.set('sortField', 'employee_name');
            }
        },
        setupController: function setupController(controller, model) {
            controller.set('loadDataParams', model);
            this._super(controller, model);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.send('selectAll', false);
                this.refresh();
            }
        }
    });
});