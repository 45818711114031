define('tm3/router', ['exports', 'ember-router-scroll', 'tm3/config/environment'], function (exports, _emberRouterScroll, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Router = Ember.Router.extend(_emberRouterScroll.default, {
        location: _environment.default.locationType,
        rootURL: _environment.default.rootURL,
        metrics: Ember.inject.service(),

        goBack: Ember.inject.service(),

        didTransition: function didTransition() {
            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
            }

            var infos = args[0];

            this._super(args);
            this.get('goBack').trackOrigin(infos, this.targetState.routerJs.state.queryParams);
            Ember.run.scheduleOnce('afterRender', this, this.sendMetric);
        },
        sendMetric: function sendMetric() {
            var metrics = this.get('metrics');
            var page = this.currentRouteName === undefined ? 'unknown' : this.currentRouteName;

            metrics.trackPage({ page: page });
        }
    });

    /* eslint-disable-next-line array-callback-return */
    Router.map(function () {
        this.route('matters', { restNamespace: true });
        this.route('matter-new');
        this.route('matter-list');

        this.route('matter', {
            restNamespace: true,
            path: 'matters/:matter_id'
        }, function () {
            this.route('dashboard');
            this.route('tasks', function () {
                this.route('info', { path: 'info/:task_id' });
                this.route('add-multiple');
            });
            this.route('updates');
            this.route('smm', function () {
                this.route('detail', {
                    path: '/:subject_id'
                });
            });
            this.route('report-components');

            this.route('detail', function () {
                this.route('edit');
                this.route('info');
            });

            this.route('calendar');

            this.route('report-wizard');

            this.route('workflows', function () {
                this.route('list');
            });

            this.route('subjects', function () {
                this.route('new');
                this.route('list', { path: '/' });
                this.route('list');
                this.route('info', { path: 'info/:subject_id' }, function () {
                    this.route('documents');
                    this.route('notes');
                    this.route('workflow');
                    this.route('db-memos', function () {
                        this.route('find-db');
                    });
                    this.route('social-links');
                    this.route('addresses');
                    this.route('posts');
                });
                this.route('import');
            });

            this.route('document-new', { path: 'documents/add' });

            this.route('documents', function () {
                this.route('info', { path: 'info/:document_id' });
                this.route('edit', { path: 'edit/:document_id' });
                this.route('search');
                this.route('keywords-list-search');
            });
            this.route('document-fields');

            this.route('admin', function () {
                this.route('accounts', function () {
                    this.route('edit', {
                        path: '/edit/:firm_id'
                    });
                    this.route('list');
                });
                this.route('fields');

                this.route('invoice-groups', function () {
                    this.route('detail', {
                        path: '/detail/:invoice_group_id'
                    });
                });
                this.route('employees');
                this.route('teams');
                this.route('workflows');

                this.route('budgets');
                this.route('contacts');
                this.route('sync-tm-data');
                this.route('reports');
                this.route('reports-by-employee', {
                    path: '/reports/by-employee/:employee_id'
                });
                this.route('reports-by-type', {
                    path: '/reports/by-type/:billable_type_id'
                });
                this.route('scrub-data');
                this.route('billing-summary');
                this.route('doc-reports');
                this.route('visualizations');
                this.route('config');
            });

            // New routes for billing
            this.route('billable-groups', function () {
                this.route('list');
                this.route('edit', {
                    path: '/edit/:billable_group_id'
                });
                this.route('info', {
                    path: '/info/:billable_group_id'
                });
            });

            this.route('db-memos', function () {
                this.route('find-db');
                this.route('browse-memos');
                this.route('for-db', {
                    path: '/for-db/:db_id'
                });
            });

            this.route('notes');

            this.route('timelines');
            this.route('report-notes');
            this.route('timekeeper-rate-sheet');
            this.route('analytics');
            this.route('renumber-tabs');
            this.route('uploads');
            this.route('sent-reports');
        });

        this.route('subjects');

        this.route('tags', function () {
            this.route('browse');
        });

        this.route('tasks');

        this.route('dbs');

        this.route('me', function () {
            this.route('avatar');
            this.route('dashboard');
            this.route('notifications');
            this.route('notification-settings');
            this.route('travel', function () {
                this.route('add');
                this.route('list');
                this.route('edit', { path: '/edit/:billable_group_id' });
            });
            this.route('time', function () {
                this.route('info', {
                    path: '/info/:billable_group_id'
                });
                this.route('list');
                this.route('edit', { path: '/edit/:billable_group_id' });
            });
            this.route('tasks');
            this.route('activity');
            this.route('receivables');
            this.route('reimbursements');
            this.route('scheduling');
        });

        this.route('auth', function () {
            this.route('login');
            this.route('logout');
        });

        // start system admin section
        this.route('admin', function () {
            this.route('matter-type', function () {
                this.route('list');
                this.route('info', { path: '/info/:matter_type_id' }, function () {
                    this.route('rate-list');
                    this.route('matter-field-default');
                    this.route('workflow-default');
                    this.route('configurations');
                });
            });
            this.route('appl-list', function () {
                this.route('list');
                this.route('info', { path: '/info/:id' });
                this.route('edit', { path: '/edit/:id' });
                this.route('new', { path: '/new/:id' });
            });
            this.route('groups');
            this.route('dbs', function () {
                this.route('form', { path: '/form/:id' });
            });
            this.route('task-list-templates', function () {
                this.route('edit', { path: '/edit/:id' });
            });
            this.route('employees', function () {
                this.route('list');
                this.route('new');
                this.route('info', { path: '/info/:id' }, function () {
                    this.route('details');
                    this.route('groups-and-teams');
                    this.route('reports');
                    this.route('billing');
                    this.route('skills');
                });
            });
            this.route('teams', function () {
                this.route('list');
                this.route('team', { path: '/:id' }, function () {
                    this.route('members');
                    this.route('matters');
                });
            });
            this.route('scopes', function () {
                this.route('index', { path: '/' });
                this.route('scope', { path: '/:id' });
            });
            this.route('report-component-templates', function () {
                this.route('index', { path: '/' });
            });

            this.route('appl-list-categories', function () {
                this.route('list');
                this.route('info', { path: '/info/:id' });
                this.route('edit', { path: '/edit/:id' });
                this.route('new');
            });

            this.route('appl-list-category', { path: '/appl-list-category/:id' }, function () {
                this.route('appl-list', function () {
                    this.route('list');
                    this.route('info', { path: '/info/:list_id' });
                    this.route('edit', { path: '/edit/:list_id' });
                    this.route('new');
                });
            });
            this.route('appl-lists-categories', function () {
                this.route('list', { path: '/list/:list_id' });
            });

            this.route('field-phrases');
            this.route('subject-note-templates');
            this.route('phrases');
            this.route('announcements');
            this.route('field-templates');
        });

        // let's build a CRM!
        this.route('crm', function () {
            this.route('accounts', function () {
                this.route('list', { path: '/' });
                this.route('list');
                this.route('info', { path: '/info/:firm_id' }, function () {
                    this.route('matters', function () {
                        this.route('add-matter');
                    });
                    this.route('contacts');
                    this.route('activity');
                    this.route('deals');
                });
                this.route('edit', { path: '/edit/:firm_id' });
            });

            this.route('contacts', function () {
                this.route('info', { path: '/info/:user_id' }, function () {
                    this.route('matters', function () {
                        this.route('add-matter');
                    });
                    this.route('accounts', function () {
                        this.route('info', function () {
                            this.route('activity');
                            this.route('deals');
                        });
                    });
                    this.route('deals');
                    this.route('activity', function () {
                        this.route('add-note');
                    });

                    this.route('edit', function () {
                        this.route('client-firms');
                        this.route('client-details');
                    });
                    this.route('access');
                    this.route('merge');
                    this.route('email-logs');
                });
                this.route('list');
                this.route('edit', { path: '/edit/:client_id' }, function () {
                    this.route('client-details');
                    this.route('client-urls');
                    this.route('client-addrs');
                    this.route('client-numbers');
                });
            });
            this.route('deals', function () {
                this.route('list');
                this.route('info', { path: '/info/:id' });
                this.route('edit', { path: '/edit/:id' });
            });

            this.route('activity', function () {
                this.route('list');
                this.route('info', { path: '/info/:id' });
            });
            this.route('dash');
            this.route('full-search');
            this.route('todos');

            this.route('tags', function () {
                this.route('browse');
            });
            this.route('copper-monitoring');
        });

        this.route('preview', { path: 'preview/:document_id' }, function () {
            this.route('document-events');
            this.route('by-subject', function () {
                this.route('subject-events', { path: ':subject_id' });
            });
            this.route('report-browser');
            this.route('file-browser');
        });
        this.route('unauthorized');

        // Catch routes not found
        this.route('bugs');
        this.route('case-management', function () {
            this.route('global-list');
            this.route('info', { path: 'info/:collection_id' }, function () {
                this.route('admin', function () {
                    this.route('fields');
                });
                this.route('edit');
                this.route('cases');
                this.route('calendar');
                this.route('edit-chm', { path: 'edit-chm/:collection_has_matter_id' });
            });
        });

        this.route('report', function () {
            this.route('list');
            this.route('info', function () {
                this.route('fake-report');
                this.route('client-file-report');
                this.route('utilization-report');
                this.route('review-time-report');
                this.route('sales-report');
                this.route('billed-cases-by-month');
                this.route('billed-cases-detail');
                this.route('new-cases-by-month');
                this.route('new-cases');
                this.route('new-cases-detail');
                this.route('revenue-summary');
                this.route('billing-report-by-person');
                this.route('managers-billing-report');
                this.route('case-profitability-report');
                this.route('revenue-quickbooks-report');
                this.route('expense-receipt');
                this.route('prelim-by-case-type');
                this.route('wip-by-case');
                this.route('cut-time-entries');
                this.route('credit-card-reconciliation');
                this.route('my-time');
                this.route('travel-receipt');
                this.route('unpaid-invoice-outputs');
                this.route('combined-reimbursement');
                this.route('payment-report');
                this.route('budget-utilization');
                this.route('bonus-report');
                this.route('fixed-cost-database-report');
                this.route('report-sent-activity');
                this.route('project-tracker');
                this.route('invoiced-customers');
                this.route('invoiced-invoices');
                this.route('invoiced-payments');
                this.route('caseload-planner');
            });
        });
        this.route('appl-lists-categories');

        this.route('accounting', function () {
            this.route('billing', function () {
                this.route('batches', function () {
                    this.route('list');
                    this.route('info', { path: '/info/:invoice_batch_id' }, function () {
                        this.route('summary');
                        this.route('print');
                    });
                    this.route('in-progress');
                });

                this.route('invoices', function () {
                    this.route('info', { path: '/info/:invoice_id' });
                    this.route('list');
                });

                this.route('manual', function () {
                    this.route('list');
                });

                this.route('create-batch');

                this.route('subscription-costs', function () {
                    this.route('list');
                    this.route('edit', { path: '/edit/:id' });
                    this.route('add');
                    this.route('info', { path: '/info/:id' });
                    this.route('processes', function () {
                        this.route('run');
                        this.route('info', { path: '/info/:id' });
                    });
                });
                this.route('invoice-outputs');
                this.route('manual-expense', function () {
                    this.route('list');
                    this.route('add');
                    this.route('edit', { path: '/edit/:billable_group_id' });
                    this.route('import');
                });
                this.route('review-time');
                this.route('tax-rules', function () {
                    this.route('add');
                    this.route('form', { path: '/form/:id' });
                });
                this.route('travel-expenses', function () {
                    this.route('info', { path: '/info/:billable_group_id' });
                });
                this.route('employee-reimbursement');
                this.route('receivables');
            });
        });

        this.route('conflicts');
        this.route('calendar');

        this.route('smm');
        this.route('scheduling');
        this.route('copper-monitoring');
        this.route('freestyle');
        this.route('four-oh-four', { path: '*path' });

        this.route('case-group', function () {
            this.route('edit-columns', { path: ':collection_id/edit-columns' });
            this.route('edit-matter', { path: ':collection_id/edit-matter/:matter_id' });
        });
        this.route('matter-list-dashboard');
        this.route('workflows-browser');
        this.route('client-collections');
    });

    exports.default = Router;
});