define('tm3/pods/accounting/billing/batches/info/summary/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'invoice',
        currentRoute: 'accounting.billing.batches.info.summary',

        model: function model(params) {
            var parentModel = this.modelFor('accounting.billing.batches.info');
            var allParams = params;
            allParams['invoice_batch_id'] = parentModel.model.id;
            allParams['include_private_tags'] = true;
            this.currentParams = allParams;
            return allParams;
        },

        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', resolved);
            this._super(controller, resolved);
            var parentModel = this.modelFor('accounting.billing.batches.info');
            this.set('controller.invoiceBatch', parentModel.model);
        },


        actions: {
            refreshModel: function refreshModel() {
                this.send('fetch');
            },
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});