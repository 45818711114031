define('tm3/pods/components/social-link-form/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var Levels = ['High activity', 'Medium activity', 'Low activity'];

    exports.default = Ember.Component.extend({
        activityLevels: Levels,

        displayOptions: null,

        useTmOptions: false,
        appName: 'js-admin',

        reRender: true,

        typeHasFollowSystem: Ember.computed('link.type', function () {
            return ['X-Twitter', 'Instagram', 'Pinterest', 'TikTok'].includes(this.get('link.type'));
        }),

        typeHasLikesSystem: Ember.computed('link.type', function () {
            return ['TikTok'].includes(this.get('link.type'));
        }),

        typeShowsFollowingFirst: Ember.computed('link.type', function () {
            return ['X-Twitter', 'TikTok'].includes(this.get('link.type'));
        }),

        init: function init() {
            for (var _len = arguments.length, rest = Array(_len), _key = 0; _key < _len; _key++) {
                rest[_key] = arguments[_key];
            }

            this._super(rest);

            this.set('displayOptions', ['Research Disclaimer']);
        },


        observeHyperlink: Ember.observer('link.hyperlink', function () {
            var allowed = ['http://', 'https://'];
            var value = this.get('link.hyperlink') || '';
            var len = value.length;

            if (value) {
                var fix = true;

                for (var i = 0; i < allowed.length; i += 1) {
                    var protocol = allowed[i];

                    if (len <= protocol.length) {
                        if (protocol.substring(0, len) === value) {
                            fix = false;

                            break;
                        }
                    } else if (value.substring(0, protocol.length) === protocol) {
                        fix = false;

                        break;
                    }
                }

                if (fix) {
                    this.set('link.hyperlink', 'http://' + value);
                }
            }
        }),

        observerScrapeRequest: Ember.observer('link.requestScrape', function () {
            var link = this.get('link');

            if (link.get('isNew') && link.get('requestScrape')) {
                link.set('automaticScrape', 0);
            }
        }),

        actions: {
            usePhrase: function usePhrase(phrase) {
                var existingNotes = this.get('link.notes');
                var notes = existingNotes ? existingNotes + '\n' : '';

                notes += phrase.get('phrase');
                this.set('reRender', false);
                this.set('link.notes', notes);
                Ember.run.next(this, function () {
                    this.set('reRender', true);
                });
            },
            saveLink: function saveLink() {
                var link = this.get('link');

                if (link.get('type') === 'Facebook') {
                    link.set('automaticScrape', 1);
                }
                this.sendAction('saveLink');
            },
            changeScrapeType: function changeScrapeType(value) {
                this.set('link.automaticScrape ', value);
            },
            linkTypeChanged: function linkTypeChanged(linkType) {
                var _linkType$getProperti = linkType.getProperties('display', 'value'),
                    newLinkTypeDisplay = _linkType$getProperti.display,
                    newLinkTypeValue = _linkType$getProperti.value;

                var link = this.get('link');

                var _link$getProperties = link.getProperties('isNew', 'requestScrape'),
                    isNew = _link$getProperties.isNew,
                    requestScrape = _link$getProperties.requestScrape;

                // Update the link `type`


                link.set('type', newLinkTypeValue);

                // If new then set the link `display`
                if (isNew) {
                    link.set('display', newLinkTypeDisplay);
                }

                switch (newLinkTypeValue) {
                    case 'LinkedIn':
                        link.setProperties({
                            deactivateLink: true,
                            viewable: 0
                        });

                        break;

                    case 'Non-Subject':
                        if (isNew) {
                            link.setProperties({
                                deactivateLink: false,
                                viewable: 0
                            });
                        } else {
                            link.setProperties({
                                deactivateLink: false
                            });
                        }

                        break;

                    case 'Other':
                    case 'None':
                        link.setProperties({
                            deactivateLink: false,
                            viewable: 2
                        });

                        break;

                    case 'Note':
                        link.setProperties({
                            deactivateLink: false,
                            viewable: 2
                        });

                        if (isNew) {
                            link.set('display', 'Research Disclaimer');
                        }

                        break;

                    case 'Facebook':
                        link.setProperties({
                            deactivateLink: false,
                            automaticScrape: 1,
                            viewable: 2
                        });

                        break;

                    default:
                        link.setProperties({
                            deactivateLink: false,
                            viewable: 2
                        });

                        break;
                }

                if (newLinkTypeValue !== 'Facebook' && requestScrape) {
                    link.set('automaticScrape', 0);
                }
            }
        }
    });
});