define('tm3/pods/crm/activity/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        // export default Ember.Route.extend(Paginate, {
        modelName: 'crm-note',

        // the name of the current controller since I don't know how to auto detect
        controllerName: 'crm.activity.list',
        currentRoute: 'crm.activity.list',

        // ask for additional data to be sideloaded
        model: function model(params) {
            params.with = 'all';
            params.comment = 0;
            params.limit = '25';

            return Ember.RSVP.hash({
                employees: this.store.query('employee', { status: 'Active', sort: 'last_name' }),
                notesParams: params,
                noteCategories: this.store.query('crm-note-category', { sort: 'name' })
            });
        },
        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', resolved.notesParams);
            this._super(controller, resolved);
            controller.set('employees', resolved.employees);
            controller.set('noteCategories', resolved.noteCategories);
        },


        actions: {
            refresh: function refresh() {
                this.refresh();
            }
        }
    });
});