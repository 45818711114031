define('tm3/mixins/related-subject-table', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        /**
         * for a given matter and subject type, remove fields that should not be displayed for that subject type
         */
        subjectTypeAlias: Ember.computed.alias('model.subjectType'),
        matterFieldsAlias: Ember.computed.alias('matterFields'),
        matterFieldsFilteredSubjectTypeFields: Ember.computed('matterFieldsAlias', 'subjectTypeAlias', function () {
            var subjectType = this.get('subjectTypeAlias');
            var matterFields = this.get('matterFieldsAlias');
            var relatedTableFields = [{ field: 'cases', table: 'juror_subjects' }, { field: 'locationOrder', table: 'juror_subjects' }, { field: 'foreperson', table: 'juror_subjects' }, { field: 'courtPanel', table: 'juror_subjects' }, { field: 'courtJuror', table: 'juror_subjects' }, { field: 'injured', table: 'plaintiff_subjects' }, { field: 'broughtSuit', table: 'plaintiff_subjects' }];
            var fieldsToRemove = [];
            switch (subjectType) {
                case 'juror_subjects':
                    fieldsToRemove = relatedTableFields.rejectBy('table', 'juror_subjects');
                    break;
                case 'plaintiff_subjects':
                    fieldsToRemove = relatedTableFields.rejectBy('table', 'plaintiff_subjects');
                    break;
                case 'witness_subjects':
                    fieldsToRemove = relatedTableFields.rejectBy('table', 'witness_subjects');
                    break;
                case 'other_subjects':
                    fieldsToRemove = relatedTableFields.rejectBy('table', 'other_subjects');
                    break;
                case 'decedent_subjects':
                    fieldsToRemove = relatedTableFields.rejectBy('table', 'decedent_subjects');
                    break;
                default:
                    fieldsToRemove = relatedTableFields;
            }

            fieldsToRemove.push({ field: 'publishedOn', table: '' }, { field: 'publishedBy', table: '' });

            return matterFields.reject(function (field) {
                return fieldsToRemove.findBy('field', Ember.String.camelize(field.get('value'))) !== undefined;
            });
        }),

        // various subject types
        subjectTypeList: [Ember.Object.create({ value: 'juror_subjects', display: 'Juror' }), Ember.Object.create({ value: 'plaintiff_subjects', display: 'Plaintiff' }), Ember.Object.create({ value: 'witness_subjects', display: 'Witness' }), Ember.Object.create({ value: 'other_subjects', display: 'Other' }), Ember.Object.create({ value: 'decedent_subjects', display: 'Decedent' })]
    });
});