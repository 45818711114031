define('tm-common/mixins/document-model', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Ember.Mixin.create({
        name: attr('string'),
        size: attr('number'),
        reportName: attr('string'),
        tabNumber: attr('number'),
        indexedOn: attr('estdate'),
        tmNum: attr('number'),
        description: attr('string'),
        type: attr('string'),
        docName: attr('string'),
        docType: attr('string'),
        docTypeOrder: attr('number'), // only used for sorting not backed on the API

        ocrStatus: attr('number'),
        notes: attr('string'),

        parentId: attr('string'),

        // resource fields
        childTable: attr('string'),
        syncId: attr('number'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),
        viewableOn: attr('estdate'),
        viewable: attr('number'),
        doNotUse: attr('number'),

        // has the logged in user viewed this resource recently
        hasBeenViewed: attr('number'),

        // UI properties
        isEditing: attr('boolean', { defaultValue: false }),
        weight: attr('number', { defaultValue: 0 }),

        firstName: attr('string'),
        lastName: attr('string'),

        groupBy: attr('string'),

        ocrIndexStatus: attr('string', { defaultValue: "not-processed" }),
        ocrIndexPostedOn: attr('estdate'),

        // relationship
        matter: belongsTo('matter', {
            async: true
        }),
        report: belongsTo('report', { async: false }),
        post: belongsTo('post', { async: false }),
        scrape: belongsTo('scrape', { async: false }),
        subject: belongsTo('subject', { async: false }),
        additionalSubjects: hasMany('subject', { async: false, serialize: true }),
        createdBy: belongsTo('user', { async: true }),
        updatedBy: belongsTo('user', { async: true }),
        timelineEvents: hasMany('timeline-event', {
            async: false
        }),

        // make sure related subjects, resource_has_tags, tags are loaded into store when using this
        fullName: Ember.computed('subject', 'docType', 'name', 'resourceHasTags.[]', function () {
            var fullName = '';
            var subject = this.get('subject');
            var docType = this.get('docType');
            var resourceHasTags = this.get('resourceHasTags');
            var name = this.get('name');

            if (subject) {
                fullName += subject.get('fullName');
            }

            if (docType) {
                fullName += fullName ? ' - ' : '';
                fullName += docType;
            }

            var fileSplitByStop = name.split('.');
            var fileExtension = fileSplitByStop[fileSplitByStop.length - 1];
            fileSplitByStop.pop();
            var fileName = fileSplitByStop.join('.');

            fullName += fullName ? ' - ' : '';
            fullName += fileName;

            var tags = '';
            resourceHasTags.forEach(function (rht) {
                if (rht.get('tag.isInternal')) {
                    return;
                }
                tags += ' (' + rht.get('tag.name') + ')';
            });

            fullName += tags;
            fullName += '.' + fileExtension;

            return fullName;
        }),

        hasBeenIndexed: Ember.computed('indexedOn', function () {
            var indexedOn = this.get('indexedOn');
            if (indexedOn) {
                return indexedOn.getFullYear() > 1901; // if the file does not exist, a decoy date is set.
            }
        }),

        hasBeenOCR: Ember.computed('ocrStatus', function () {
            return this.get('ocrStatus') >= 3; // corresponds to already OCR
        }),

        isDeletable: Ember.computed('docType', {
            get: function get(type) {
                return type === '';
            }
        }),

        faIconName: Ember.computed('type', function () {
            switch (this.get('type')) {
                case 'application/pdf':
                    return 'file-pdf-o';
                case 'application/vnd.ms-office':
                case 'application/msword':
                case 'application/html':
                case 'text/html':
                case 'text/rtf':
                case 'application/csv':
                    return 'file-text-o';
                case 'application/vnd.ms-excel':
                    return 'file-excel-o';
                case 'application/vnd.ms-powerpoint':
                    return 'file-powerpoint-o';
                case 'image/jpeg':
                case 'image/tiff':
                case 'image/png':
                case 'image/gif':
                case 'image/x-ms-bmp':
                    return 'file-image-o';
                case 'video/mpeg':
                    return 'file-video-o';
                default:
                    return 'file-o';
            }
        }),

        /**
         * calculate a friendly file size for display
         */
        friendlySize: Ember.computed('size', function () {
            var bytes = this.get('size');
            var decimals = 0;

            if (bytes === 0) {
                return '0';
            }
            var k = 1024; // Or 1 kilo = 1000
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
            var i = Math.floor(Math.log(bytes) / Math.log(k));
            if (i === 0) {
                i = 1;
                bytes = bytes / 1024;
                return parseFloat(bytes).toFixed(2) + ' ' + sizes[i];
            }
            return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
        }),

        groupField: Ember.computed('report', 'docType', 'subject', 'groupBy', function () {
            var groupBy = this.get('groupBy');
            if (groupBy === 'reportName') {
                return this.get('report.name');
            } else if (groupBy === 'subject') {
                if (this.get('subject.lastName') === null || this.get('subject.lastName') === undefined) {
                    return null;
                }
                return this.get('subject.fullName');
            } else if (groupBy === 'viewable') {
                switch (this.get(groupBy)) {
                    case 0:
                        return 'Private';
                    case 1:
                        return 'Public';
                    case 2:
                        return 'Pending';
                }
            } else if (groupBy) {
                return this.get(groupBy);
            }
        }),

        docTypeReadName: Ember.computed('docType', function () {
            var readName = '';
            switch (this.get('docType')) {
                case 'BANK':
                case 'BIRTH':
                case 'CIVIL':
                case 'CLIENT':
                case 'COMP REPT':
                case 'DEATH':
                case 'EXPERT':
                case 'LAW ENFORCEMENT':
                case 'MEDICAL':
                case 'NEWS':
                case 'PHOTO':
                case 'QUESTIONNAIRE':
                case 'REPORT':
                case 'REPORT TAB':
                case 'PROFILE PHOTO':
                case 'SCRAPE':
                case 'TRAFFIC':
                case 'VEHICLE':
                case 'UNCLASSIFIED':
                    readName = this.get('docType').replace(/\w\S*/g, function (txt) {
                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    });
                    break;

                case 'CLIENT_UPLOAD':
                    readName = 'Client Upload';
                    break;

                case 'CRIM':
                    readName = 'Criminal';
                    break;

                case 'EDU':
                    readName = 'Education';
                    break;

                case 'EMPL/BUS':
                    readName = 'Employment';
                    break;

                case 'PROP':
                    readName = 'Property';
                    break;

                case 'MARITAL/DOMESTIC':
                    readName = 'Marital';
                    break;

                case 'OTHER/MISC':
                    readName = 'Other';
                    break;

                case 'WEB/SOCNET':
                    readName = 'Social Media';
                    break;

                default:
                    readName = this.get('docType');
                    break;
            }

            return readName;
        }),

        nameOfReport: Ember.computed('report', function () {
            return this.get('report') ? this.get('report.name') : 'No Report';
        }),

        ocrIndexStatusLabel: Ember.computed('ocrIndexStatus', function () {
            switch (this.get('ocrIndexStatus')) {
                case 'not-processed':
                    return 'Not Processed';
                case 'processing':
                    return 'Processing';
                case 'done':
                    return 'Done';
                case 'failed':
                    return 'Failed';
                default:
                    return 'Unknown';
            }
        }),

        ocrIndexStatusIcon: Ember.computed('ocrIndexStatus', function () {
            switch (this.get('ocrIndexStatus')) {
                case 'not-processed':
                    return 'file-o';
                case 'processing':
                    return 'gear';
                case 'done':
                    return 'file-text-o';
                case 'failed':
                    return 'exclamation-circle';
                default:
                    return 'file-pdf-o';
            }
        }),

        ocrAndIndex: attr('number', { defaultValue: 0 }),

        searchDocuments: (0, _emberApiActions.collectionAction)({ path: 'search', type: 'get' }),

        ocrTheFile: (0, _emberApiActions.memberAction)({ path: 'ocr-the-file', type: 'post' })
    });
});