define('tm-common/components/document-edit-table', ['exports', 'ember-component-inbound-actions/inbound-actions', 'tm-common/templates/components/document-edit-table'], function (exports, _inboundActions, _documentEditTable) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inboundActions.default, {
        layout: _documentEditTable.default,
        classNames: ['col-md-12', 'p-0'],
        subjects: null,
        store: Ember.inject.service(),
        jsStyle: false,

        massAttributes: Ember.computed('filesToUpload', function () {
            return {
                docType: undefined,
                subject: undefined,
                report: undefined,
                tab: undefined,
                viewable: 0,
                acceptWarning: false
            };
        }),

        isNewlyUploaded: true,

        tagsTaskObserver: Ember.on('init', Ember.observer('isNewlyUploaded', function () {
            if (!this.get('isNewlyUploaded')) {
                var filesToUpload = this.get('filesToUpload');

                this.get('documentSelection').forEach(function (doc) {
                    var docToUpload = filesToUpload.findBy('id', doc.get('id'));
                    var resourceHasTags = doc.hasMany('resourceHasTags').value();

                    if (resourceHasTags) {
                        docToUpload.set('tags', resourceHasTags.mapBy('tag'));
                    }
                });
            }
        })),

        actions: {
            massDocumentChanged: function massDocumentChanged(val) {
                this.set('massAttributes.docType', val);
                var files = this.get('filesToUpload');

                files.forEach(function (file) {
                    file.set('docType', val.get('value'));
                });
            },
            massSubjectChanged: function massSubjectChanged(val) {
                this.set('massAttributes.subject', val);
                var files = this.get('filesToUpload');

                files.forEach(function (file) {
                    file.set('subject', val);
                });
            },
            massReportChanged: function massReportChanged(val) {
                this.set('massAttributes.report', val);
                var files = this.get('filesToUpload');

                files.forEach(function (file) {
                    file.set('report', val);
                });
            },
            massTagsChanged: function massTagsChanged(val) {
                this.set('massAttributes.tags', val);
                var files = this.get('filesToUpload');

                files.forEach(function (file) {
                    file.set('tags', val);
                });
            },
            massTabChanged: function massTabChanged(val) {
                this.set('massAttributes.tab', val);
                var files = this.get('filesToUpload');

                files.forEach(function (file) {
                    file.set('tabNumber', val);
                });
            },
            massViewableChanged: function massViewableChanged(newViewable) {
                this.set('massAttributes.viewable', newViewable);
                var files = this.get('filesToUpload');

                files.forEach(function (file) {
                    file.set('viewable', newViewable);
                });
            },
            massDoNotUseChanged: function massDoNotUseChanged(newVal) {
                this.set('massAttributes.doNotUse', newVal);
                var files = this.get('filesToUpload');

                files.forEach(function (file) {
                    file.set('doNotUse', newVal);
                });
            },
            acceptAll: function acceptAll(newVal) {
                var files = this.get('filesToUpload');

                files.forEach(function (file) {
                    file.set('acceptWarning', newVal);
                });
            },
            ocrAll: function ocrAll(newVal) {
                var files = this.get('filesToUpload');

                files.forEach(function (file) {
                    file.set('ocrAndIndex', newVal);
                });
            },


            /** power-select for subject */
            searchSubject: function searchSubject(term) {
                var _this = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this, _this._performSearchSubject, term, resolve, reject, 600);
                });
            },
            remove: function remove(fileToRemove) {
                this.sendAction('remove', fileToRemove);
            },
            upload: function upload() {
                this.get('xUploader').send('upload');
            },
            cancelUpload: function cancelUpload() {
                this.set('filesToUpload', []);
            },
            ok: function ok() {
                this.sendAction('ok', false);
            }
        },
        _performSearchSubject: function _performSearchSubject(term, resolve, reject) {
            return this.get('store').query('subject', {
                'first_name||last_name': '*' + term + '*',
                matter_id: this.get('matter.id'),
                limit: 100,
                sortField: 'last_name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});