define('tm3/pods/components/matter/dashboard/client-documents/component', ['exports', 'moment', 'tm-common/mixins/crud/error', 'tm3/mixins/save-event', 'ember-changeset'], function (exports, _moment, _error2, _saveEvent, _emberChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_error2.default, _saveEvent.default, {
        title: 'Client Documents',
        route: 'matter.documents',
        linkText: 'View All Documents',
        store: Ember.inject.service(),
        lists: Ember.inject.service(),
        currentMatter: Ember.inject.service(),
        popover: Ember.inject.service(),
        notify: Ember.inject.service(),
        maxDocs: 10,

        didReceiveAttrs: function didReceiveAttrs() {
            this._super();
            this.set('documents', []);
            this.getDocuments();
        },


        matterId: Ember.computed('matterId', 'currentMatter.id', function () {
            return this.get('matterId') ? this.get('matterId') : this.get('currentMatter.id');
        }),

        getDocuments: function getDocuments() {
            var _this = this;

            this.get('store').query('document', {
                matter_id: this.get('matterId'),
                doc_type: ['REPORT', 'CLIENT', 'CLIENT_UPLOAD', 'COMPLAINT'],
                with: 'v_resource_has_resources,subjects,resource_has_tags,reports'
            }).then(function (data) {
                _this.set('documents', data);
                _this.get('documents.content').sort(function (a, b) {
                    return (0, _moment.default)(b._data.createdOn).diff((0, _moment.default)(a._data.createdOn));
                });
            });
        },


        actions: {
            markDocumentAsViewed: function markDocumentAsViewed(doc) {
                doc.set('hasBeenViewed', 1);
            },
            editDocument: function editDocument(doc) {
                this.set('documentSelection', [doc]);
                this.set('documentSelectionCopy', [new _emberChangeset.default(doc)]);
                this.set('showModal', true);
                this.get('popover').close();
            },
            dropChanges: function dropChanges() {
                if (!this.get('saving')) {
                    var docs = this.get('documentSelectionCopy');

                    docs.forEach(function (doc) {
                        if (doc.get('isDirty')) {
                            doc.rollback();
                        }
                    });
                }
            },
            saveChanges: function saveChanges() {
                // allow upload file directly from here
                var uploadFileNode = $('input[type=file]').get(0);

                if (uploadFileNode && uploadFileNode.value) {
                    this.get('documentEditTable').send('upload');

                    // The after Upload will trigger the applyChanges
                } else {
                    return this.send('applyChanges');
                }
            },
            applyChanges: function applyChanges() {
                var _this2 = this;

                var docs = this.get('documentSelectionCopy');
                var newTags = docs.map(function (d) {
                    return { id: d.get('id'), tags: d.get('tags') || [] };
                });

                this.set('saving', true);

                var promises = [];
                var deletePromises = [];

                docs.forEach(function (doc) {
                    if (doc.get('isDirty')) {
                        var originalDoc = _this2.get('documentSelection').findBy('id', doc.get('id'));
                        var oldResourcesHaveTags = originalDoc.hasMany('resourceHasTags').value();

                        if (oldResourcesHaveTags) {
                            deletePromises.pushObjects(oldResourcesHaveTags.map(function (rht) {
                                return rht.destroyRecord();
                            }));
                        }
                    }
                });

                return Ember.RSVP.all(deletePromises).then(function () {
                    docs.forEach(function (doc) {
                        if (doc.get('isDirty')) {
                            promises.push(doc.save().catch(function (error) {
                                _this2.validationReport(doc);
                                doc.get('_content').rollbackAttributes();
                                doc.get('_content').reload();
                                throw error;
                            }));
                            var originalDoc = _this2.get('documentSelection').findBy('id', doc.get('id'));
                            var selectedTags = newTags.findBy('id', doc.get('id')).tags;

                            selectedTags.forEach(function (tag) {
                                var rht = _this2.get('store').createRecord('resource-has-tag', {
                                    tag: tag,
                                    resource: originalDoc
                                });

                                promises.push(rht.save());
                            });
                        }
                    });

                    return Promise.all(promises).then(function () {
                        _this2.set('showModal', false);
                    }).finally(function () {
                        _this2.set('saving', false);
                    });
                });
            },


            /** actions called during replace a document * */
            beforeUpload: function beforeUpload(filesToUpload) {
                this.get('notify').warning('Starting to upload the file to replace');
                var documentToUpdate = this.get('documentSelectionCopy.firstObject');

                filesToUpload.forEach(function (f) {
                    // Set the overwrite ID
                    f.set('overwriteDocumentId', documentToUpdate.get('id'));
                });
            },
            afterUpload: function afterUpload(response) {
                var _this3 = this;

                response.documents.forEach(function (d) {
                    var doc = _this3.get('documentSelectionCopy').findBy('id', d.id);

                    if (doc) {
                        doc.set('type', d.type);
                        doc.set('size', d.size);
                        doc.set('file_name', d.file_name);
                    }
                });
                this.get('notify').success('Document uploaded');

                // to trigger the update after the upload
                this.get('documentSelectionCopy').forEach(function (x) {
                    return x.set('changed', true);
                });
                this.send('applyChanges');
                this.set('showModal', false);
            },
            errorUpload: function errorUpload(_error) {
                this.get('notify').error('There was a problem uploading the files to replace');
            },
            cancelUpload: function cancelUpload() {
                this.get('documentEditTable').send('cancelUpload');
            }
        }
    });
});