define('tm3/pods/me/tasks/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        session: Ember.inject.service(),
        modelName: 'task',
        currentRoute: 'me.tasks',

        queryParams: {
            highPriority: {
                refreshModel: true
            }
        },

        model: function model(params) {
            params.assigned_to_id = this.get('session.data.authenticated.id');
            params.completed_only = false;
            params.exclude_workflow = true;
            params.show_completed_cases = true;
            params.only_active_cases = true;
            if (params.highPriority) {
                params.only_urgent = true;
            } else {
                params.only_urgent = false;
            }
            delete params.highPriority;
            return Ember.RSVP.hash({
                tasksParams: params
            });
        },
        setupController: function setupController(controller, model) {
            controller.set('loadDataParams', model.tasksParams);
            this._super(controller, model);
        }
    });
});