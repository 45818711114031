define('tm3/pods/accounting/billing/subscription-costs/list/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column'], function (exports, _controller, _column) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        // logic to handle sorting a list
        sortField: 'id',
        useTableSettings: false,

        // load pager specific variables
        columns: [_column.default.create({ displayName: 'DB', fieldName: 'db', order: 0 }), _column.default.create({ displayName: 'Subscription Type', fieldName: 'type', order: 1 }), _column.default.create({ displayName: 'Renews', fieldName: 'renews', order: 2 }), _column.default.create({ displayName: 'Employee', fieldName: 'employee.fullName', order: 3 }), _column.default.create({ displayName: 'Method', fieldName: 'method', order: 4 }), _column.default.create({ displayName: 'Monthly Cost', fieldName: 'monthlyFormatted', apiName: 'monthly', order: 5 }), _column.default.create({ displayName: 'Annual Cost', fieldName: 'annualFormatted', apiName: 'annual', order: 6 })],

        // need to open single matter record
        linkPath: 'accounting.billing.subscription-costs.info',

        tableTitle: 'Subscription Costs',

        toolsParams: Ember.computed(function () {
            return {
                buttonLink: 'accounting.billing.subscription-costs.add',
                buttonText: 'New Subscription Cost'
            };
        }),

        actions: {}
    });
});