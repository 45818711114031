define('tm3/pods/crm/accounts/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var appLists = ['States', 'crm_region', 'crm_rating', 'crm_sc_business', 'crm_industry'];

    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'firm',
        lists: Ember.inject.service(),

        // the name of the current controller since I don't know how to auto detect
        controllerName: 'crm.accounts.list',
        currentRoute: 'crm.accounts.list',

        model: function model(params) {
            return Ember.RSVP.hash({
                params: params,
                lists: this.get('lists').fetchMultipleLists(appLists)
            });
        },


        actions: {
            // use the willTransition route action to check if the transition target is 'crm.accounts.list'
            // if it is, then we don't reset quickSearch because it is a refresh not a transition.
            // willTransition only fires when we are currently in a route and so if it is firing, we are already in
            // the route and therefore we should be transitioning to another route unless it is a refresh
            willTransition: function willTransition(transition) {
                var target = transition.targetName;
                if (target !== 'crm.accounts.list') {
                    this.controller.set('quickSearch', null);
                }
            },
            refresh: function refresh() {
                this.refresh();
            }
        },

        // extend to pass route values onto controller for possible use
        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', resolved.params);
            this._super(controller, resolved);

            // pass route properties on to controller
            controller.set('modelName', this.modelName);
            controller.set('controllerName', this.controllerName);

            if (controller.get('sortField') === '-created_on') {
                controller.set('sortFieldInitial', controller.get('sortOptions').findBy('display', 'Most recent'));
            }

            appLists.forEach(function (listName) {
                controller.set(listName.toLowerCase(), resolved.lists[listName]);
            });
        }
    });
});