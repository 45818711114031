define('tm3/pods/accounting/billing/subscription-costs/list/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'subscription-cost',
        currentRoute: 'accounting.billing.subscription-costs.list',

        requiredPermissions: ['Admin Time & Expense'],

        setupController: function setupController(controller, model) {
            controller.set('loadDataParams', {
                with: 'all'
            });
            this._super(controller, model);
        },


        actions: {
            doNothing: function doNothing() {
                // Do nothing at all
            }
        }
    });
});