define('tm3/pods/components/documents/subject-page-row/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        notify: Ember.inject.service(),

        isReport: false,
        isTab: false,
        isOther: false,

        actions: {
            sendToOCR: function sendToOCR(doc) {
                var _this = this;

                doc.ocrTheFile().then(function () {
                    _this.get('notify').success('Document has been sent to OCR and Index');
                }).finally(function () {
                    doc.reload();
                });
            }
        }
    });
});