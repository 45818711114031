define('tm3/pods/crm/accounts/list/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'tm-common/mixins/crud/error'], function (exports, _controller, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, _error.default, {
        notify: Ember.inject.service(),
        // load pager specific variables
        columns: [],
        // need to open single matter record
        linkPath: 'crm.accounts.info.activity',

        // the name of a field to search by
        quickSearchField: 'full_name',
        sortField: '-created_on',
        with: 'matter_counts',
        useTableSettings: false,

        showAccountModal: false,
        newAccount: null,

        sortFieldInitial: undefined,
        sortOptions: [{ direction: 'asc', field: 'name', display: 'Name' }, { direction: 'desc', field: 'created_on', display: 'Most recent' }, { direction: 'asc', field: 'created_on', display: 'Less recent' }, {
            direction: 'desc',
            field: 'updated_on',
            display: 'Most recently updated'
        }, { direction: 'asc', field: 'updated_on', display: 'Less recent updated' }],
        toolsParams: Ember.computed('quickSearchField', function () {
            return {
                options: this.get('sortOptions'),
                initial: this.get('sortFieldInitial'),
                quickSearchField: this.get('quickSearchField')
            };
        }),

        // Add custom parameters for the table-pagination component
        titleParams: Ember.computed(function () {
            var _this = this;

            return {
                icon: 'building',
                newItemClass: 'btn btn-box-tool',
                newItemLabel: 'New Account',
                // newItemLink: 'crm.accounts.add'
                newItemAction: function newItemAction() {
                    _this.send('openNewAccountModal');
                }
            };
        }),
        tableTitle: 'Accounts',
        perPage: 20,

        actions: {
            openNewAccountModal: function openNewAccountModal() {
                this.set('newAccount', this.store.createRecord('firm'));
                this.set('showAccountModal', true);
            },
            closeNewAccountModal: function closeNewAccountModal() {
                this.get('newAccount').destroy();
                this.set('newAccount', null);
                this.set('showAccountModal', false);
            },
            save: function save() {
                var _this2 = this;

                var account = this.get('newAccount');
                // Possible names
                var names = Ember.A();
                var name = account.get('name');
                if (typeof name === 'undefined') {
                    name = '';
                }

                names.pushObject(name);
                names.pushObject(name.toLowerCase());
                names.pushObject(name.toUpperCase());
                name = name.replace('&', 'and');
                names.pushObject(name);
                names.pushObject(name.toLowerCase());
                names.pushObject(name.toUpperCase());
                name = name.toUpperCase().replace(' AND ', ' & ');
                names.pushObject(name);
                names.pushObject(name.toLowerCase());
                names.pushObject(name.toUpperCase());

                // Check if account name is not duplicated for the same city
                this.store.queryRecord('firm', {
                    name: names,
                    city: account.get('city')
                }).then(function (firm) {
                    if (Ember.isEmpty(firm)) {
                        account.save().then(function (data) {
                            _this2.get('notify').success('New account added!');
                            _this2.set('showAccountModal', false);
                            _this2.set('newAccount', null);
                            _this2.send('refresh');
                        }, function (reason) {
                            _this2.validationReport(account);
                        });
                    } else {
                        _this2.get('notify').error('Account name is duplicated');
                    }
                });
            }
        }
    });
});