define('tm3/pods/me/tasks/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column'], function (exports, _controller, _column) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        store: Ember.inject.service(),
        sortField: '-created_on',

        with: 'task_lists',
        quickSearchField: 'comment',

        highPriority: false,
        useTableSettings: false,

        queryParams: ['highPriority'],

        columnFields: Ember.computed('columns', function () {
            return this.get('columns').sortBy('order').map(function (c) {
                return c.get('apiInteractionName');
            });
        }),
        columnNames: Ember.computed('columns', function () {
            return this.get('columns').sortBy('order').map(function (c) {
                return c.get('displayName');
            });
        }),

        columns: [_column.default.create({
            displayName: 'Case #',
            fieldName: 'taskList.matter.tmCode',
            apiName: 'matter_code',
            width: '80px',
            order: 0
        }), _column.default.create({
            displayName: 'Case Name/Style',
            fieldName: 'taskList.matter.caseNickname',
            apiName: 'matter_name',
            width: '300px',
            order: 1
        }), _column.default.create({
            displayName: 'Task List',
            fieldName: 'taskList.name',
            apiName: 'task_lists:name',
            width: '200px',
            order: 2
        }), _column.default.create({
            displayName: 'Task Name',
            fieldName: 'comment',
            apiName: 'comment',
            cellComponent: 'custom-table-pagination/hover-cell',
            order: 3
        }), _column.default.create({
            displayName: 'Due Date',
            fieldName: 'dueOn',
            apiName: 'due_on',
            width: '90px',
            order: 4,
            cellComponent: 'custom-table-pagination/date-cell',
            advFilterComponent: 'table-filter-components/date'
        }), _column.default.create({
            displayName: 'Created On',
            fieldName: 'createdOn',
            apiName: 'created_on',
            width: '90px',
            order: 5,
            cellComponent: 'custom-table-pagination/date-cell'
        }), _column.default.create({
            displayName: 'Status',
            fieldName: 'status',
            apiName: 'status',
            width: '90px',
            order: 6,
            cellComponent: 'my-tasks/cell/status',
            advFilterComponent: 'table-filter-components/task-status',
            acceptedOperators: ['in']
        }), _column.default.create({
            displayName: 'High Priority',
            fieldName: 'urgent',
            apiName: 'priority',
            width: '80px',
            order: 7,
            cellComponent: 'my-tasks/cell/priority'
        })],

        additionalColumnsForFilter: [_column.default.create({
            displayName: 'Comments',
            fieldName: 'notes',
            apiName: 'notes',
            order: 1
        })],

        linkPath: 'matter.tasks.info',

        actions: {
            toggleHighPriority: function toggleHighPriority() {
                this.set('page', 1);
                this.toggleProperty('highPriority');
            }
        }
    });
});