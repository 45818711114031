define('tm3/pods/accounting/billing/create-batch/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column', 'moment'], function (exports, _controller, _column, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, {
        notify: Ember.inject.service(),
        session: Ember.inject.service(),
        ajax: Ember.inject.service(),

        queryParams: ['matterType', 'cutDate'],
        matterType: null,
        cutDate: null,
        useTableSettings: false,

        jsCutDate: null,

        observeDate: Ember.observer('jsCutDate', function () {
            if (this.get('jsCutDate')) {
                this.set('cutDate', (0, _moment.default)(this.get('jsCutDate')).format('YYYY-MM-DD'));
            } else {
                this.set('cutDate', null);
            }
        }),

        // load pager specific variables
        columns: [_column.default.create({ displayName: 'Status', fieldName: 'status', order: 0 }), _column.default.create({ displayName: 'TM#', fieldName: 'tmNum', order: 1 }), _column.default.create({ displayName: 'Created', fieldName: 'createdOn', order: 2 }), _column.default.create({
            displayName: 'Invoices',
            fieldName: 'invoiceCount',
            order: 3,
            disableServerInteractions: true
        }), _column.default.create({
            displayName: '$ Time',
            fieldName: 'billedAmount',
            order: 4,
            disableServerInteractions: true
        })],
        // need to open single matter record
        linkPath: 'accounting.billing.batches.info',

        tableTitle: 'Invoice Batches',

        // Add custom parameters for the table-pagination component
        titleParams: {
            icon: 'building',
            newItemClass: 'btn btn-box-tool',
            newItemLabel: 'Create Invoice Batch',
            newItemLink: 'accounting.billing.create-batch'
        },

        contentParams: Ember.computed(function () {
            var _this = this;

            return {
                checkAll: function checkAll(value) {
                    _this.send('checkAll', value);
                }
            };
        }),

        isSavingNewBatch: false,

        actions: {
            changeFilter: function changeFilter(matterType) {
                if (matterType) {
                    this.set('matterType', matterType.get('prefix'));
                    this.set('matterTypeFilterObject', matterType);
                    this.set('matterTypeFilter', Ember.isPresent(matterType) ? matterType.get('id') : null);
                } else {
                    this.set('matterType', '');
                    this.set('matterTypeFilterObject', null);
                    this.set('matterTypeFilter', Ember.isPresent(matterType) ? matterType.get('id') : null);
                }
            },
            createInvoiceBatch: function createInvoiceBatch() {
                var _this2 = this;

                var name = this.get('invoiceBatchName');
                var cutDate = this.get('jsCutDate');
                var igIds = [];

                var billables = this.get('model');
                billables.forEach(function (item) {
                    var selected = item.get('selected');
                    if (selected) {
                        igIds.push({
                            invoice_group_id: item.get('invoiceGroupId'),
                            selected_budget_ids: item.get('budgets').filterBy('selected').mapBy('id')
                        });
                    }
                });

                if (igIds.length > 0) {
                    this.set('isSavingNewBatch', true);

                    var postObj = {};
                    postObj.name = name;
                    postObj.invoice_group_ids = JSON.stringify(igIds);
                    if (cutDate) {
                        postObj.cut_date = (0, _moment.default)(cutDate).format('YYYY-MM-DD');
                    }

                    this.get('ajax').post('invoice_batches', { data: postObj }).then(function (response) {
                        _this2.get('notify').success('Invoice batch created successfully');
                        _this2.set('isSavingNewBatch', false);
                        _this2.transitionToRoute('accounting.billing.batches.info.summary', response.invoice_batches.id);
                    }, function () {
                        _this2.get('notify').error('Upload error');
                    });
                } else {
                    this.get('notify').error('You must select 1 or more items to create a batch');
                }
            },
            checkAll: function checkAll(value) {
                var billables = this.get('model');
                billables.forEach(function (item) {
                    item.set('selected', value);
                });
            }
        }
    });
});