define('tm3/pods/me/activity/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-cli-table-pagination/mixins/table-data/column', 'tm-common/mixins/crud/error'], function (exports, _controller, _column, _error) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend(_controller.default, _error.default, {
        // logic to handle sorting a list
        sortField: '-created_on',
        useTableSettings: false,

        // load data specific variables
        columns: [_column.default.create({
            displayName: 'Date',
            fieldName: 'createdOnFormatted',
            apiName: 'created_on',
            width: '100px',
            order: 0
        }), _column.default.create({ displayName: 'Description', fieldName: 'description', width: '80%', order: 2 }), _column.default.create({ displayName: 'File #', fieldName: 'matter.tmCode', apiName: 'matters:tm_code', order: 3 })],

        columnFields: Ember.computed('columns', function () {
            return this.get('columns').sortBy('order').map(function (c) {
                return c.get('apiInteractionName');
            });
        }),
        columnNames: Ember.computed('columns', function () {
            return this.get('columns').sortBy('order').map(function (c) {
                return c.get('displayName');
            });
        }),
        columnWidths: Ember.computed('columns', function () {
            return ['25', '150', '25'];
        }),

        quickSearchField: 'description',

        with: 'matters'
    });
});