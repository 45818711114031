define('tm3/pods/matters/route', ['exports', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, {
        modelName: 'matter',

        currentRoute: 'matters',

        // the name of the current controller since I don't know how to auto detect
        controllerName: 'matters',

        requiredPermissions: ['See Case'],

        queryParams: {
            quickSearchField: {
                refreshModel: true
            },
            q: {
                refreshModel: true
            }
        },

        setupController: function setupController(controller, resolved) {
            controller.set('loadDataParams', {});
            this._super(controller, resolved);

            if (typeof controller.get('quickSearch') === 'string') {
                controller.set('q', controller.get('quickSearch'));
            }
        },


        actions: {
            didTransition: function didTransition() {
                var self = this;

                Ember.run.schedule('afterRender', self, function () {
                    Ember.$('.quickSearchInput').focus();
                });
            },
            willTransition: function willTransition(transition) {
                this.controller.set('addTimeToMatter', null);

                return true;
            }
        }
    });
});