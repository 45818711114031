define('tm3/pods/crm/contacts/list/controller', ['exports', 'ember-cli-table-pagination/mixins/table-data/controller', 'ember-lifeline/mixins/run', 'tm-common/mixins/crud/error', 'ember-validations'], function (exports, _controller, _run, _error, _emberValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var PhoneHolder = Ember.Object.extend({ number: '', type: '', counter: 0 });

    exports.default = Ember.Controller.extend(_controller.default, _run.default, _error.default, _emberValidations.default, {
        notify: Ember.inject.service(),
        queryParams: ['quickSearchField', 'q'],

        // ignore since template doesn't use
        columns: [],

        showContactModal: false,
        newContact: null,
        copyEmail: false,

        // need to open single matter record
        linkPath: 'crm.contacts.info.accounts',

        tableTitle: 'Contacts',

        // use the api name for this field
        quickSearchField: 'full_name',

        sortField: '',
        perPage: 50,
        with: 'firm_has_clients',
        useTableSettings: false,

        sortFieldInitial: undefined,
        sortOptions: [{ direction: 'asc', field: 'last_name,first_name', display: 'Name' }, { direction: 'desc', field: 'created_on', display: 'Most recent' }, { direction: 'asc', field: 'created_on', display: 'Less recent' }, { direction: 'desc', field: 'updated_on', display: 'Most recently updated' }, { direction: 'asc', field: 'updated_on', display: 'Less recent updated' }],
        toolsParams: Ember.computed('quickSearchField', function () {
            return {
                options: this.get('sortOptions'),
                initial: this.get('sortFieldInitial'),
                quickSearchField: this.get('quickSearchField')
            };
        }),

        titleParams: Ember.computed(function () {
            var _this = this;

            return {
                newItemAction: function newItemAction() {
                    _this.send('openNewContactModal');
                }
            };
        }),

        observeQuickSearch: Ember.observer('quickSearch', function () {
            this.debounceTask('updateQuery', 600);
        }),

        updateQuery: function updateQuery() {
            this.set('q', this.get('quickSearch'));
        },


        validations: {
            'newContact.firstName': { presence: true },
            'newContact.lastName': { presence: true },
            'newContact.email': { presence: true }
        },
        phoneHolderCounter: 1,

        // account selected to create the firm-has-client
        selectedAccount: null,
        matchingAccountName: '',

        // reference to the avatar-cropper component to send the upload action
        avatarCropper: null,

        resetPhoneInput: function resetPhoneInput() {
            this.set('additionalPhoneHolders', []);
            this.set('phoneHolderCounter', 1);
            var initialPhoneHolder = this.get('initialPhoneHolder');
            initialPhoneHolder.set('number', '');
            this.set('currentPhoneHolder', this.get('initialPhoneHolder'));
        },


        actions: {
            openNewContactModal: function openNewContactModal() {
                this.set('newContact', this.store.createRecord('client'));
                this.set('showContactModal', true);
            },
            closeNewContactModal: function closeNewContactModal() {
                this.get('newContact').destroy();
                this.set('newContact', null);
                this.set('firmRelationship', this.get('relationships.firstObject'));
                this.set('selectedAccount', null);
                this.resetPhoneInput();
                this.set('linkToFirmAddress', false);
                this.set('showContactModal', false);
            },
            saveContact: function saveContact() {
                this.get('avatarCropper').send('uploadAvatar');
            },
            addContact: function addContact() {
                var _this2 = this;

                var model = this.get('newContact');

                this.validate().then(function () {
                    // if user elects to use the email for their username
                    if (_this2.get('copyEmail')) {
                        model.set('userName', model.get('email'));
                    }

                    model.save().then(function (data) {
                        _this2.store.findRecord('user', data.get('id')).then(function (user) {
                            var initialPhone = _this2.get('initialPhoneHolder');
                            var additionalPhones = _this2.get('additionalPhoneHolders');
                            if (initialPhone.get('number') !== '') {
                                var phone = _this2.store.createRecord('user-number', {
                                    user: user,
                                    type: initialPhone.get('type'),
                                    number: initialPhone.get('number')
                                });
                                phone.save();

                                additionalPhones.forEach(function (currentPhone) {
                                    if (currentPhone.get('number') !== '') {
                                        var _phone = _this2.store.createRecord('user-number', {
                                            user: user,
                                            type: currentPhone.get('type'),
                                            number: currentPhone.get('number')
                                        });
                                        _phone.save();
                                    }
                                });
                            }

                            var associatedFirm = _this2.get('selectedAccount');
                            var firmRelationship = _this2.get('firmRelationship.value');

                            var promises = [];

                            if (associatedFirm != null && associatedFirm.get('id') !== '' && associatedFirm.get('name') !== '') {
                                promises.push(_this2.store.findRecord('firm', associatedFirm.get('id')).then(function (firm) {
                                    var fhc = _this2.store.createRecord('firm-has-client', {
                                        firm: firm,
                                        user: user,
                                        relationship: firmRelationship
                                    });

                                    var fhcPromise = fhc.save().then(function (data) {
                                        _this2.get('notify').success('New account has been associated with this contact');
                                        return data;
                                    }, function (reason) {
                                        this.validationReport(fhc);
                                    });

                                    // If checkbox is checked to link address, create the user_addrs
                                    var userAddrsPromise = null;
                                    if (_this2.get('linkToFirmAddress')) {
                                        userAddrsPromise = _this2.store.createRecord('user-addr', {
                                            user: user,
                                            firm: firm,
                                            type: 'Work',
                                            primary: true,
                                            addr_1: firm.get('address'),
                                            addr_2: firm.get('suite'),
                                            city: firm.get('city'),
                                            state: firm.get('state'),
                                            zip: firm.get('zip')
                                        }).save();
                                    }

                                    return Ember.RSVP.hash({
                                        fhcPromise: fhcPromise,
                                        userAddrs: userAddrsPromise
                                    });
                                }));
                            }
                            // wait for all promises to finish
                            Ember.RSVP.all(promises).then(function () {
                                _this2.get('notify').success('New contact added');
                                _this2.set('newContact', null);
                                _this2.set('firmRelationship', _this2.get('relationships.firstObject'));
                                _this2.resetPhoneInput();
                                _this2.set('selectedAccount', null);
                                _this2.set('linkToFirmAddress', false);
                                _this2.set('showContactModal', false);
                            });
                        });
                    }, function (reason) {
                        _this2.validationReport(model);
                    });
                }, function () {
                    _this2.notifyClientSideValidations(_this2, 'newContact');
                });
            },
            initialPhoneNumber: function initialPhoneNumber() {
                Ember.$('.phone_placeholder').toggleClass('hidden');
                Ember.$('#phone_holder').toggleClass('hidden');
            },
            closePhoneHolder: function closePhoneHolder(item) {
                if (item.get('counter') === 1) {
                    this.set('additionalPhoneHolders', []);
                    this.set('phoneHolderCounter', 1);
                    var initialPhoneHolder = this.get('initialPhoneHolder');
                    initialPhoneHolder.set('number', '');

                    this.send('initialPhoneNumber');
                    this.toggleAddPhoneLink('off');
                } else {
                    var additionalPhoneHolders = this.get('additionalPhoneHolders');
                    additionalPhoneHolders.removeObject(item);

                    if (additionalPhoneHolders.isEmpty()) {
                        this.set('currentPhoneHolder', this.get('initialPhoneHolder'));
                    } else {
                        this.set('currentPhoneHolder', additionalPhoneHolders.get('lastObject'));
                    }

                    // var currentCounter = this.get('phoneHolderCounter')
                    // currentCounter--
                    // NOTE(leo): @jking -> looks like we are missing an assignment here this.set('phoneHolderCounter', currentCounter--) ?
                }
            },
            addAdditionalPhone: function addAdditionalPhone() {
                var currentCounter = this.get('phoneHolderCounter');
                currentCounter++;
                this.set('phoneHolderCounter', currentCounter);

                var newHolder = PhoneHolder.create({
                    number: '',
                    type: 'Work',
                    counter: currentCounter
                });

                this.set('currentPhoneHolder', newHolder);

                var additionalPhoneHolders = this.get('additionalPhoneHolders');
                additionalPhoneHolders.pushObject(newHolder);
            },


            /** power-select for accounts */
            searchAccount: function searchAccount(term) {
                var _this3 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this3, _this3._performSearchAccount, term, resolve, reject, 600);
                });
            }
        },

        currentPhoneHolderInputCheck: Ember.observer('currentPhoneHolder.number', function () {
            if (this.get('currentPhoneHolder.number') !== '') {
                this.toggleAddPhoneLink('on');
            } else {
                this.toggleAddPhoneLink('off');
            }
        }),

        toggleAddPhoneLink: function toggleAddPhoneLink(state) {
            if (state === 'on') {
                Ember.$('#addNewPhone').removeClass('hidden');
            } else {
                Ember.$('#addNewPhone').addClass('hidden');
            }
        },
        _performSearchAccount: function _performSearchAccount(term, resolve, reject) {
            return this.store.query('firm', {
                name: '*' + term + '*',
                limit: 100,
                sortField: 'name'
            }).then(function (items) {
                return resolve(items);
            });
        }
    });
});