define('tm-common/components/document-edit-table/table-row', ['exports', 'tm-common/templates/components/document-edit-table/table-row', 'tm-common/utils/doc-name-parsing'], function (exports, _tableRow, _docNameParsing) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),

        layout: _tableRow.default,
        tagName: 'div',
        classNames: ['row', 'document-table-row'],
        expanded: false,
        alwaysExpanded: true,

        subjects: null,
        scrapes: null,
        showRemove: true,
        showTab: true,
        employeesToAlert: [],
        caseEmployees: null,
        caseTeams: null,
        caseEmployeesProxy: null,
        caseTeamsProxy: null,

        observeEmployeesToAlert: Ember.observer('employeesToAlert.[]', function () {
            this.set('fileToUpload.extra', {
                employeesToAlert: this.get('employeesToAlert').mapBy('id').join(',')
            });
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            var _this = this;

            this._super.apply(this, arguments);

            var fileToUpload = this.get('fileToUpload');

            if (!this.get('isNewlyUploaded')) {
                // we don't process file name when editing a document
                return;
            }

            var parsedNameResult = (0, _docNameParsing.default)(fileToUpload.get('name'), this.get('currentSubject'), this.get('subjects'), this.get('documentTypes'));

            this.set('fileToUpload.notes', fileToUpload.get('name')); // Original name
            if (Ember.isEmpty(this.get('fileToUpload.docType'))) {
                this.set('fileToUpload.docType', parsedNameResult.docType);
            }
            this.set('fileToUpload.tabNumber', parsedNameResult.tabNumber);
            this.set('fileToUpload.name', parsedNameResult.fileName);
            this.set('fileToUpload.tags', []);

            if (Ember.isEmpty(this.get('fileToUpload.subject'))) {
                // set subject
                this.set('fileToUpload.subject', parsedNameResult.subject);
                if (this.get('fileToUpload.subject') == null) {
                    this.set('askForSubject', true);
                }
            }

            if (!this.get('fileToUpload.subject') && !this.get('subjects')) {
                this._performSearchSubject({
                    term: '',
                    matterId: this.get('matterId'),
                    limit: 20
                }, function (items) {
                    _this.set('subjects', items);
                });
            }

            // create proxies for alert employees panel
            var caseEmployees = this.get('caseEmployees');
            var caseTeams = this.get('caseTeams');
            if (caseEmployees) {
                var caseEmployeesProxy = caseEmployees.map(function (ce) {
                    return Ember.ObjectProxy.create({
                        isSelected: false,
                        content: ce
                    });
                });
                this.set('caseEmployeesProxy', caseEmployeesProxy);
            }
            if (caseTeams) {
                var caseTeamsProxy = caseTeams.map(function (ce) {
                    return Ember.ObjectProxy.create({
                        isSelected: false,
                        content: ce
                    });
                });
                this.set('caseTeamsProxy', caseTeamsProxy);
            }

            fileToUpload.set('employeesToAlert', []);
        },


        actions: {
            setDocType: function setDocType(docType) {
                if (docType) {
                    this.set('fileToUpload.docType', docType.get('value'));
                } else {
                    this.set('fileToUpload.docType', 'undefined');
                }
            },

            /** power-select for subject */
            searchSubject: function searchSubject(term) {
                var _this2 = this;

                return new Ember.RSVP.Promise(function (resolve, reject) {
                    Ember.run.debounce(_this2, _this2._performSearchSubject, {
                        matterId: _this2.get('matterId'),
                        term: term
                    }, resolve, reject, 600);
                });
            },

            remove: function remove(file) {
                this.sendAction('remove', file);
            },
            reportChanged: function reportChanged(report) {
                this.set('fileToUpload.report', report);
            }
        },
        _performSearchSubject: function _performSearchSubject(searchParams, resolve, reject) {
            var _this3 = this;

            return this.get('store').query('subject', {
                'first_name||last_name': '*' + searchParams.term + '*',
                matter_id: searchParams.matterId,
                limit: searchParams.limit || 100,
                sortField: 'last_name'
            }).then(function (items) {
                return resolve(items.rejectBy('id', _this3.get('fileToUpload.subject.id')));
            });
        }
    });
});