define('tm3/pods/components/sticky-header/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    var globalCounter = 0;

    exports.default = Ember.Component.extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);

            this.set('sentinel_id', 'sticky-sentinel-' + globalCounter);
            this.set('id', 'sticky-element-' + globalCounter);
            globalCounter++;
        },
        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);

            var element = document.getElementById(this.get('id'));
            var sentinel = document.getElementById(this.get('sentinel_id'));
            if (!element) return; // should never happen

            this.observer = new IntersectionObserver(function (e) {
                return element.classList.toggle('is-sticky', !e[0].isIntersecting);
            });

            this.observer.observe(sentinel);
        },
        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            if (this.observer) this.observer.disconnect();
        }
    });
});