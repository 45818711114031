define('tm3/helpers/get-comma-delimited-items', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.getCommaDelimitedItems = getCommaDelimitedItems;


    /**
        Provides an html-safe string of `span.comma-delimited-item` elements based
        on the passed in `collection` and optional `propertyName`. (If
        `propertyName` is omitted then the collection is assumed to contain
        primitive values.)
    
        @method getCommaDelimitedItems
        @param {Object} hash Object containing the `collection` member and optional `propertyName` value
        @returns {SafeString}
     */
    function getCommaDelimitedItems(params, hash) {
        var collection = hash.collection,
            propertyName = hash.propertyName;


        if (Ember.isEmpty(collection)) {
            return '';
        }

        return Ember.String.htmlSafe(collection.map(function (item) {
            return '<span class="comma-delimited-item">' + (propertyName ? Ember.get(item, propertyName) : item) + '</span>';
        }).join(' '));
    }

    exports.default = Ember.Helper.helper(getCommaDelimitedItems);
});