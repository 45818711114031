define('tm3/pods/me/activity/route', ['exports', 'tm-common/mixins/crud/error', 'ember-cli-table-pagination/mixins/table-data/route'], function (exports, _error, _route) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_route.default, _error.default, {
        modelName: 'passive-event',
        currentRoute: 'me.activity',

        currentUser: Ember.inject.service(),

        model: function model(params) {
            params['created_by_id'] = this.get('currentUser.authUserId');
            params['event_action'] = ['New', 'Delete'], params['with'] = 'matters';
            params['exclude_jury'] = true;
            return params;
        },
        setupController: function setupController(controller, model) {
            controller.set('loadDataParams', model);
            this._super(controller, model);
        }
    });
});