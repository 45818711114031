define('tm3/pods/components/matter/dashboard/tasks-widget/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didReceiveAttrs: function didReceiveAttrs() {
            this._super();
            var allTasks = [];

            this.get('taskLists').forEach(function (tl) {
                tl.get('tasks').forEach(function (t) {
                    allTasks.pushObject(t);
                });
            });

            this.set('notStartedPct', 0);
            this.set('waitingPct', 0);
            this.set('completedPct', 0);
            this.set('notStartedCount', 0);
            this.set('waitingCount', 0);
            this.set('completedCount', 0);
            this.set('allTasks', allTasks);

            if (allTasks.get('length')) {
                var notStarted = allTasks.filterBy('status', 'I').get('length');
                var waiting = allTasks.filterBy('status', 'P').get('length');
                var completed = allTasks.filterBy('status', 'C').get('length');

                this.set('notStartedPct', (notStarted / allTasks.get('length') * 100).toFixed(2));
                this.set('waitingPct', (waiting / allTasks.get('length') * 100).toFixed(2));
                this.set('completedPct', (completed / allTasks.get('length') * 100).toFixed(2));

                this.set('notStartedCount', notStarted);
                this.set('waitingCount', waiting);
                this.set('completedCount', completed);
            }
        }
    });
});